<template>
  <div ref="cursor" class="cursor justify-center items-center" :class="{
    'flex': isHovered,
    'hidden': !isHovered
  }" :style="{ left: `${x - 50}px`, top: `${y - 70}px` }">
    <p class='text-center leading-none uppercase font-semibold text-white'>View</p>
  </div>
</template>

<script setup lang="ts">
import { useMouse } from "@vueuse/core";
const props = defineProps<{
  target?: string;
}>();
const { isHovered } = useHoverElement(props.target || "case-study-card");
const { x, y } = useMouse({ touch: false, type: "client" });
const cursor = ref();
</script>

<style scoped lang="scss">
.cursor {
  @apply fixed pointer-events-none z-[9998] backdrop-blur-sm rounded-full transition duration-300 bg-white/10 h-[100px] w-[100px];
}

@keyframes rotateCursor {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
